import React from 'react';
import './style.css';

const CoupleSection2 = (props) => {

    return (
        <section className="wpo-couple-section section-padding" id="couple">
            <div className="container">
                <div className="couple-area clearfix">
                    <div className="row justify-content-center">
                        <div className="couple-item">
                            <div className="couple-text">
                                <h3>Informations</h3>

                                <div>
                                    <p>
                                        Pour des raisons de logistique, les enfants sont acceptés à la Mairie mais ne
                                        sont malheureusement pas conviés pour le reste de la journée (hormis les enfants
                                        d'honneurs).
                                    </p>
                                </div>

                                <div>
                                    <p>
                                        Mariage corbeille : participation à votre bon coeur.
                                    </p>
                                </div>

                                <div>
                                    <p>
                                        Dress code : Élégant.
                                    </p>
                                </div>

                                <p><i><b>Afin de nous organiser au mieux, merci à tous de renseigner le formulaire
                                    ci-dessous.</b></i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoupleSection2;