import React from 'react'
import {Slide} from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/1.jpg'
import sImg3 from '../../images/event/3.jpg'
import sImg10 from '../../images/event/10.jpg'

const Events = [
    {
        Simg: sImg1,
        title: 'Cérémonie civile',
        li1: '10h30',
        li2: 'Mairie de Nouméa',
        li3: 'Merci d\'être présent dès 10h'
    },
    {
        Simg: sImg10,
        title: 'Tour des baies',
        li1: 'Sur le chemin de Céline et Matthieu  (110 rue du Cirque - Robinson 98809 Mont-Dore)',
    },
    {
        Simg: sImg3,
        title: 'Apéro. Repas. Wedding cake. Danse. Cocktail dînatoire',
        li1: 'Jusqu\'à 21h30',
        li2: 'Chez Céline et Matthieu (110 rue du Cirque - Robinson 98809 Mont-Dore)',
    }
]

const EventSection = (props) => {
    return (
        <section className="wpo-event-section section-padding" id="event">
            <div className="container">
                <SectionTitle MainTitle={'Festivités'}/>
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-2 col-md-2 col-12 mx-auto text-center" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-img">
                                            <div className="wpo-event-img-inner">
                                                <img src={event.Simg} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <ul>
                                                <li>{event.li1}</li>
                                                <li><i>{event.li2}</i></li>
                                                <li><strong>{event.li3}</strong></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;